<template>
    <v-container class="py-8 px-6 pl-12" fluid>
        <v-card outlined style="border: 0px solid white" class="mt-n10">
            <v-card-text class="mt-n3">
                <div class="cont-title" style="float: left;">{{ pageTitle }}</div>
            </v-card-text>
        </v-card>
        <v-row class="mt-16">
            <v-col class="mb-0">
                <router-view @open-memo="openMemoListPopup" ref="refHistList"></router-view>
            </v-col>
        </v-row>

        <v-dialog v-model="modal" persistent max-width="900" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'memo_list'">
                <popup-memo-list v-on:cancel="closePopup" :prop-cust-seqno="custSeqno" :prop-cust-mdn="custMdn"></popup-memo-list>
            </v-flex>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'stats',
    components: {
        'PopupMemoList': () => import('@/components/svc_hist/popup_memo_list.vue')
    },
    data: () => ({
        modal: false,
        dialogMode: '',
        custSeqno: 0,
        custMdn: ''
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        // this.$store.commit('menuId', 'stats')
    },
    computed: {
        pageTitle() {
            let ret = '서비스 이력'
            if (this.$route && this.$route.meta && this.$route.meta.title) {
                ret = this.$route.meta.title
            }
            return ret
        }
    },
    mounted() {
    },
    methods: {
        openMemoListPopup(custInfo) {
            this.custSeqno = custInfo && custInfo.cust_seqno ? custInfo.cust_seqno : 0
            this.custMdn = custInfo && custInfo.cust_mdn ? custInfo.cust_mdn : ''
            this.dialogMode = 'memo_list'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
            this.custSeqno = 0
            this.custMdn = ''
            this.refreshHistList()
        },
        refreshHistList() {
            if (this.$refs.refHistList && this.$refs.refHistList.refresh) {
                this.$refs.refHistList.refresh()
            }
        }
    }
}
</script>

